/* eslint-disable */
/**!
 *
 * flatpickr by Gregory Petrosyan
 *
 * Credits to Josh Salverda <josh.salverda@gmail.com> for providing a
 * magnificent starting point.
 *
 * l10n modified to Danish by <kbd@hiper.dk>
 *
 * @license WTFPL <http://www.wtfpl.net/>
 * @link    <http://chmln.github.io/flatpickr/>
 */
(function(doc, win, objName, obj) {
    'use strict';

    var ac = 'appendChild';
    var ih = 'innerHTML';
    var ce = 'createElement';
    var ae = 'addEventListener';
    var re = 'removeEventListener';
    var cn = 'className';


    obj = function (selector, config) {
        var elements,
            createInstance,
            instances = [],
            i;

        obj.prototype = obj.init.prototype;

        createInstance = function (element) {
            if (element._flatpickr) {
                element._flatpickr.destroy();
            }
            element._flatpickr = new obj.init(element, config);
            return element._flatpickr;
        };

        if (selector.nodeName) {
            return createInstance(selector);
        }

        elements = doc.querySelectorAll(selector);

        if (elements.length === 1) {
            return createInstance(elements[0]);
        }

        for (i = 0; i < elements.length; i++) {
            instances.push(createInstance(elements[i]));
        }
        return instances;
    };

    /**
     * @constructor
     */
    obj.init = function (element, instanceConfig) {

        var self = this,
            calendarContainer = doc[ce]('div'),
            navigationCurrentMonth = doc[ce]('span'),
            calendar = doc[ce]('table'),
            calendarBody = doc[ce]('tbody'),
            wrapperElement,
            currentDate = new Date(),
            wrap,
            date,
            formatDate,
            monthToStr,
            isDisabled,
            buildWeekdays,
            buildDays,
            updateNavigationCurrentMonth,
            buildMonthNavigation,
            handleYearChange,
            documentClick,
            calendarClick,
            buildCalendar,
            bind,
            open,
            close,
            destroy,
            init,
            triggerChange,
            changeMonth,
            getDaysinMonth,
            jumpToDate;

        calendarContainer[cn]      = objName + '-calendar';
        navigationCurrentMonth[cn] = objName + '-current-month';
        instanceConfig             = instanceConfig || {};

        wrap = function () {
            wrapperElement = doc[ce]('div');
            wrapperElement[cn] = objName + '-wrapper';

            String(self.config.inline) == 'true' && win.hoax.addClass(wrapperElement, 'inline'); // jshint ignore:line


            self.element.parentNode.insertBefore(wrapperElement, self.element);
            wrapperElement[ac](self.element);
        };

        getDaysinMonth = function(){
            var year  = self.currentYear;
            var month = self.currentMonth;

            if (month === 1 && (new Date(year, 1, 29).getMonth() === 1)) {
                return 29;
            }

            return self.l10n.daysInMonth[self.currentMonth];
        };

        formatDate = function (dateFormat, milliseconds) {
            var formattedDate = '',
                dateObj = new Date(milliseconds),
                formats = {
                    d: function () {
                        var day = formats.j();
                        return (day < 10) ? '0' + day : day;
                    },
                    // D: function () {
                    //     return self.l10n.weekdays.shorthand[formats.w()];
                    // },
                    j: function () {
                        return dateObj.getDate();
                    },
                    // l: function () {
                    //     return self.l10n.weekdays.longhand[formats.w()];
                    // },
                    // w: function () {
                    //     return dateObj.getDay();
                    // },
                    // F: function () {
                    //     return monthToStr(formats.n() - 1, false);
                    // },
                    m: function () {
                        var month = formats.n();
                        return (month < 10) ? '0' + month : month;
                    },
                    // M: function () {
                    //     return monthToStr(formats.n() - 1, true);
                    // },
                    n: function () {
                        return dateObj.getMonth() + 1;
                    },
                    // U: function () {
                    //     return dateObj.getTime() / 1000;
                    // },
                    // y: function () {
                    //     return String(formats.Y()).substring(2);
                    // },
                    Y: function () {
                        return dateObj.getFullYear();
                    }
                },
                formatPieces = dateFormat.split('');

            self.forEach(formatPieces, function (formatPiece, index) {
                if (formats[formatPiece] && formatPieces[index - 1] !== '\\') {
                    formattedDate += formats[formatPiece]();
                } else if (formatPiece !== '\\') {
                        formattedDate += formatPiece;
                }
            });

            return formattedDate;
        };

        monthToStr = function (date, shorthand) {
            return shorthand ? self.l10n.months.shorthand[date] : self.l10n.months.longhand[date];
        };

        buildWeekdays = function () {
            var weekdayContainer = doc[ce]('thead'),
                firstDayOfWeek = self.l10n.firstDayOfWeek,
                weekdays = self.l10n.weekdays.shorthand.slice();


            if (firstDayOfWeek > 0 && firstDayOfWeek < weekdays.length) {
                weekdays = [].concat(weekdays.splice(firstDayOfWeek, weekdays.length), weekdays.splice(0, firstDayOfWeek));
            }

            weekdayContainer[ih] = '<tr><th>' + weekdays.join('</th><th>') + '</th></tr>';

            calendar[ac](weekdayContainer);
        };

        isDisabled = function(date){
            var  toDate;

            for (var i = 0; i < self.config.disable.length; i++){

                // js date is a day behind
                toDate = new Date( self.config.disable[i].to );
                toDate.setDate(toDate.getDate() + 1);

                if ( date >= new Date( self.config.disable[i].from ) && date < toDate )
                    return true;
            }

            return false;
        };

        buildDays = function () {
            var firstOfMonth = new Date(self.currentYear, self.currentMonth, 1).getDay() - self.l10n.firstDayOfWeek,
                numDays = getDaysinMonth(),
                calendarFragment = doc.createDocumentFragment(),
                row = doc[ce]('tr'),
                dayCount,
                dayNumber,
                className,
                cur_date,
                date_is_disabled,
                date_outside_minmax;

            var holidays = hoax.getHolidays(self.currentYear);

            // Offset the first day by the specified amount
            firstOfMonth < 0 && (firstOfMonth += 7); // jshint ignore:line

            // Add spacer to line up the first day of the month correctly
            firstOfMonth > 0 && (row[ih] += '<td colspan="' + firstOfMonth + '">&nbsp;</td>'); // jshint ignore:line

            dayCount = firstOfMonth;
            calendarBody[ih] = '';


            // Start at 1 since there is no 0th day
            for (dayNumber = 1; dayNumber <= 42 - firstOfMonth; dayNumber++) {
                // if we have reached the end of a week, wrap to the next line
                if (dayCount % 7 === 0) {
                    calendarFragment[ac](row);
                    row = doc[ce]('tr');
                }

                className = '';
                cur_date = new Date(self.currentYear, self.currentMonth, dayNumber);
                cur_date.setHours(0,0,0,0);

                date_is_disabled = dayNumber > numDays || (self.config.disable && isDisabled(cur_date));

                date_outside_minmax =
                    (self.config.minDate && cur_date < self.config.minDate) ||
                    (self.config.maxDate && cur_date > self.config.maxDate);


                if (!self.selectedDateObj && cur_date.valueOf() === currentDate.valueOf()) {
                    className += ' today';
                }

                if (self.selectedDateObj) {
                    if (self.selectedDateObj.getDate() === cur_date.getDate() &&
                        self.selectedDateObj.getMonth() === cur_date.getMonth() &&
                        self.selectedDateObj.getFullYear() === cur_date.getFullYear()) {
                            className += ' selected';
                    }
                }

                className += (date_is_disabled || date_outside_minmax) ? " disabled" : " slot";

                if (holidays.indexOf(
                    cur_date.getFullYear() + '-' +
                    ("0" + (cur_date.getMonth() + 1) ).slice (-2) + '-' +
                    ("0" + cur_date.getDate()).slice (-2)
                ) !== -1) {
                    className = 'disabled';
                }
                if (cur_date.getDay() === 0 || cur_date.getDay() === 6) {
                    className = 'disabled';
                }

                row[ih] +=
                    '<td class="' + className + '">' +
                    '<span class="' + objName + '-day">' +
                    (dayNumber > numDays ? dayNumber-numDays : dayNumber) +
                    '</span></td>';

                dayCount++;

            }

            calendarFragment[ac](row);
            calendarBody[ac](calendarFragment);

        };

        updateNavigationCurrentMonth = function () {
            navigationCurrentMonth[ih] = '<span>' + monthToStr(self.currentMonth, false) + '</span> ' + self.currentYear;
        };

        buildMonthNavigation = function () {
            var months = doc[ce]('div'),
                prevMonth, nextMonth;

            months[cn] = objName + '-months';

            prevMonth = doc[ce]('span');
            prevMonth[cn] = objName + "-prev-month";
            prevMonth.innerHTML = self.config.prevArrow;

            nextMonth = doc[ce]('span');
            nextMonth[cn] = objName + "-next-month";
            nextMonth.innerHTML = self.config.nextArrow;

            months[ac](prevMonth);
            months[ac](navigationCurrentMonth);
            months[ac](nextMonth);

            updateNavigationCurrentMonth();
            calendarContainer[ac](months);
        };

        handleYearChange = function () {
            if (self.currentMonth < 0) {
                self.currentYear--;
                self.currentMonth = 11;
            }

            if (self.currentMonth > 11) {
                self.currentYear++;
                self.currentMonth = 0;
            }
        };

        documentClick = function (event) {
           if (!wrapperElement.contains(event.target))
            close();
        };

        changeMonth = function(to) {
            (to === 'prev') ? self.currentMonth-- : self.currentMonth++; // jshint ignore:line
            handleYearChange();
            updateNavigationCurrentMonth();
            buildDays();
        };

        calendarClick = function (event) {
            event.preventDefault();
            var t = event.target;


            // if ( t.classList.contains('slot') || t.parentNode.classList.contains('slot') ) {
            if ( win.hoax.hasClass(t, 'slot') || win.hoax.hasClass(t.parentNode, 'slot') ) {
                var selDate          = parseInt(t.childNodes[0][ih] || t[ih], 10);
                var currentTimestamp = null;

                self.selectedDateObj = new Date(self.currentYear, self.currentMonth, selDate);
                currentTimestamp     = self.selectedDateObj.getTime();

                // TODO:
                if (self.config.altInput) {
                    doc.querySelector(self.config.altInput).value =
                    formatDate(self.config.altFormat || self.config.dateFormat, currentTimestamp);
                }



                self.element.value = formatDate(self.config.dateFormat, currentTimestamp);

                close();
                buildDays();
                triggerChange();
            }





        };

        buildCalendar = function () {
            buildMonthNavigation();
            buildWeekdays();
            buildDays();

            calendar[ac](calendarBody);
            calendarContainer[ac](calendar);
            wrapperElement[ac](calendarContainer);
        };

        bind = function () {
            if (String(self.config.inline) != 'true') {
                var openEvent = (self.element.nodeName === 'INPUT') ? 'focus'  : 'click';
                self.element[ae](openEvent, open, false);
            }

            wrapperElement.querySelector(".flatpickr-prev-month")[ae]('click', function(){ changeMonth('prev'); });
            wrapperElement.querySelector(".flatpickr-next-month")[ae]('click', function(){ changeMonth('next'); });

            calendar[ae]('click', calendarClick);
        };

        open = function () {
            self.element.blur();
            doc[ae]('click', documentClick, false);
            // wrapperElement.classList.add('open');
            win.hoax.addClass(wrapperElement, 'open');
        };

        close = function () {
            	if (self.config.inline)
            return;

            doc[re]('click', documentClick, false);
            // wrapperElement.classList.remove('open');
            win.hoax.removeClass(wrapperElement, 'open');
            self.redraw();

        };

        triggerChange = function(){

            // http://stackoverflow.com/a/18364492/9547981
            function triggerEvent(el,eventName){
                var event;
                if (doc.createEvent) {
                    event = doc.createEvent('HTMLEvents');
                    event.initEvent(eventName,true,true);
                } else if (doc.createEventObject) {// IE < 9
                    event = doc.createEventObject();
                    event.eventType = eventName;
                }
                event.eventName = eventName;
                if (el.dispatchEvent) {
                    el.dispatchEvent(event);
                } else if (el.fireEvent && htmlEvents['on'+eventName]) {// IE < 9
                    el.fireEvent('on'+event.eventType,event);// can trigger only real event (e.g. 'click')
                } else if (el[eventName]) {
                    el[eventName]();
                } else if (el['on'+eventName]) {
                    el['on'+eventName]();
                }
            }
            triggerEvent(element, 'change');
        };

        destroy = function () {
            var parent,
                element;

            doc[re]('click', documentClick, false);
            self.element[re]('focus', open, false);
            self.element[re]('blur', close, false);
            self.element[re]('click', open, false);

            parent = self.element.parentNode;
            parent.removeChild(calendarContainer);
            element = parent.removeChild(self.element);
            parent.parentNode.replaceChild(element, parent);
        };

        jumpToDate = function(jumpDate) {
            self.currentYear = jumpDate.getFullYear();
            self.currentMonth = jumpDate.getMonth();
            self.currentDayView = jumpDate.getDate();
        };

        init = function () {

            var config, parsedDate;

        	self.config = {};
            self.element = element;
            self.destroy = destroy;

        	currentDate.setHours(0,0,0,0);



            for (config in self.defaultConfig) {
                    self.config[config] = ( instanceConfig[config] || self.element.getAttribute('data-' + config.toLowerCase()) || self.defaultConfig[config]);
            }

            // firefox doesn't like dashes
            if (typeof self.config.defaultDate === 'string') {
                self.config.defaultDate = self.config.defaultDate.replace(new RegExp('-', 'g'), '/');
            }

            if ( self.element.value || (!self.element.value && self.config.defaultDate ) ){
                parsedDate = Date.parse(self.element.value||self.config.defaultDate);
                self.selectedDateObj = ( !isNaN(parsedDate) ) ?  new Date(parsedDate) : null;
            }

            if (self.config.minDate) {
                self.config.minDate = new Date(self.config.minDate);
                self.config.minDate.setHours(0,0,0,0);
            }

            if (self.config.maxDate) {
                self.config.maxDate = new Date(self.config.maxDate);
                self.config.maxDate.setHours(0,0,0,0);
            }


            jumpToDate(self.selectedDateObj||self.config.minDate||currentDate);


            wrap();
            buildCalendar();
            bind();
        };

        self.redraw = function(){
            obj(self.element, self.config);
        };

        self.set = function(key, value) {
            if (key in self.config) {
                self.config[key] = value;
                self.redraw();
            }
        };

        init();

        return self;
    };

    obj.init.prototype = {

        forEach: function (items, callback) { [].forEach.call(items, callback); },

        l10n: {
            weekdays: {
                shorthand: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
                longhand: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag']
            },
            months: {
                shorthand: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
                longhand: ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December']
            },
            daysInMonth: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
            firstDayOfWeek: 1
        },

        defaultConfig : {
                dateFormat:            'Y-m-d',
                altFormat:             null,
                altInput:              null,
                defaultDate:           null,
                minDate:               null,
                maxDate:               null,
                disable:               null,
                shorthandCurrentMonth: false,
                inline:                false,
                prevArrow:             '&lt;',
                nextArrow:             '&gt;'
        }
    };
    win[objName] = obj;
} (document, window, 'flatpickr'));
