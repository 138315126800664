(function(doc, win, $) {
    const removeClass    = 'removeClass';
    const addClass       = 'addClass';
    let dawa             = {};
    let cache            = {};
    let itemHeight       = null;
    let currentItem      = null;
    let currentData      = null;
    let addressField     = null;
    let addressList      = null;
    let navigateFromList = null;
    let globalZip        = null;

    function init(params) {
        addressField     = params.addressField;
        addressList      = params.addressList;
        navigateFromList = params.navigateFromList;
    }

    function search(string, skipStreetName) {
        $[addClass](addressList, 'loading');
        $[removeClass](addressList.parentNode, 'search-failed');
        _clearResults(addressList);

        if (skipStreetName) {
            skipStreetName = true;
        } else {
            skipStreetName = false;
        }

        askDawa(string, skipStreetName, null, data => {
            currentData = data;
            _showResults(data);
        });
    }

    /**
     * Make a DAWA XHR request
     *
     * @param  {String}   string
     * @param  {Boolean}  skipStreetName
     * @param  {String}   adgangsAdresseId
     * @param  {Callback} onSuccess
     * @param  {Callback} onError
     * @return {Void}
     */
    function askDawa(string, skipStreetName, adgangsAdresseId, onSuccess, onError) {
        let url = '';

        if (typeof window.override_dawa !== 'undefined') {
            url = window.override_dawa;
        } else {
            url = $.http + 'api.dataforsyningen.dk/autocomplete';
        }

        const perPage = 50;
        let options   = {};
        let cacheName = '';

        // Check if there's a zipcode
        globalZip = /(\d{4})/g.exec(string);
        if (globalZip !== null) {
            globalZip = globalZip[0];
        } else {
            globalZip = false;
        }

        // Define a default onError function, if not passed in params
        if (!onError) {
            onError = () => {
                $[removeClass](addressList, 'loading');
                $[addClass](addressList.parentNode, 'search-failed');
            };
        }

        // Specify the XHR URL and params
        options.url    = url;
        options.params = {
            q:        string,
            type:     'adresse',
            fuzzy:    '',
            caretpos: $.getInputSelection(addressField).start,
            per_side: perPage,
        };

        // Add the access address id, if present
        if (adgangsAdresseId !== null) {
            options.params.adgangsadresseid = adgangsAdresseId;
        }

        // Start the dawa search from the access address
        if (skipStreetName) {
            options.params.startfra = 'adgangsadresse';
        }

        // Filter DAWA search by globalZip - if present
        if (globalZip) {
            options.params.postnr = globalZip;
        }

        // Find a cacheName based on options passed to DAWA (no double requests,
        // KTHXBYE!)
        cacheName = JSON.stringify(options);

        if (cache[cacheName]) {
            // If this request is cached, return that to the sucess handler
            onSuccess(cache[cacheName]);
        } else {
            // This request is not cached, we create wrappers for XHR success
            // and error
            options.success = data => {
                const sortedData = sortDawaData(data, string);
                cache[cacheName] = sortedData;
                onSuccess(sortedData);
            };
            options.error   = () => {
                onError();
            };

            // Run the XHR
            $.xhr.setTimeout(2000);
            $.xhr.get(options.url + '?' + $.parseParams(options.params))
                .success(options.success)
                .error(options.error);
        }
    }

    /**
     * Magic! Suspense!
     *
     * This function acts dramatically differnet depending on the kind of data
     * contained within the `data` object.
     *
     * All of the results get a Sørensen–Dice coefficient assigned to a
     * `distance` property. For adress-type results, the `distance` is increased
     * by 1 if the result contains the search string (without the numbers or
     * address-parts like "tv" etc.)
     *
     * There are 4 main scenarios:
     *
     * 1. All results are roadnames - we sort the results by the `distance`.
     *
     * 2. All results are access addresses - we sort the results by the
     *   `distance`, then by the zipcode and lastly by the house number.
     *
     * 3. All results are addresses - we sort the results by the `distance`,
     *    then by the floor, and lastly by the door number.
     *
     * 4. The results are mixed with the above types - in this case, we try to
     *    provide a best guess, but it's not truly realiable.
     *
     * @param  {Object} data       Collection of search results to be sorted
     * @param  {String} stringCopy The search string - as entered by user
     * @return {Object}            Sorted collection of search results
     */
    function sortDawaData(data, stringCopy) {
        let dataCopy                    = [];
        let streetNumber                = null;
        let streetNumberAddresses       = [];
        let zipAddresses                = [];
        let countAddresses              = 0;
        let countAccessAddresses        = 0;
        let countRoads                  = 0;
        let onlyAddresses               = false;
        let onlyAccessAddresses         = false;
        let onlyRoads                   = false;
        let stringNormalized            = '';
        let deDanishizedString          = '';
        let deDanishizedAddress         = '';
        let streetNumberAddressesFirst  = [];
        let streetNumberAddressesSecond = [];

        const deDanishize = danish => $.replaceAll(danish, {
            'æ': 'ae',
            'ø': 'oe',
            'å': 'aa',
            'é': 'e',
        });

        const deNumerize = addressString => addressString.replace(/(\d{4}.*|\d+(tv|th|mf|st|kl)|\d|,|\.|)/gi, '')
            .replace(/ +/g, ' ')
            .trim();

        const sortByRealFloorThenDoor = stuff => {
            let cellarAddresses      = [];
            let groundFloorAddresses = [];
            let otherFloorAddresses  = [];

            stuff.forEach(address => {
                if (/kl/gi.test(address.data.etage)) {
                    cellarAddresses.push(address);
                } else if (/st/gi.test(address.data.etage)) {
                    groundFloorAddresses.push(address);
                } else {
                    otherFloorAddresses.push(address);
                }
            });

            cellarAddresses.sort($.dynamicNatSort('data.dør'));
            groundFloorAddresses.sort($.dynamicNatSort('data.dør'));
            otherFloorAddresses.sort($.dynamicNatSortMultiple('data.etage', 'data.dør'));

            return [].concat(cellarAddresses, groundFloorAddresses, otherFloorAddresses);
        };

        stringNormalized = deNumerize(stringCopy);
        stringNormalized = deDanishize(stringNormalized);

        data.forEach((address, index) => {
            if (address.type === 'adresse') {
                countAddresses++;

                deDanishizedString  = deDanishize(stringCopy).toLowerCase();
                deDanishizedAddress = deDanishize(address.forslagstekst).toLowerCase();

                data[index].distance = $.diceCoefficient(deDanishizedString, deDanishizedAddress);

                if (
                    deDanishizedAddress.indexOf(deDanishizedString.replace(/\d+|(tv|th|mf|st|kl)/gi, '')
                        .trim()) >= 0
                ) {
                    data[index].distance = data[index].distance + 1;
                }
            }
            if (address.type === 'adgangsadresse') {
                countAccessAddresses++;
                data[index].distance = $.diceCoefficient(stringNormalized, deDanishize(deNumerize(address.forslagstekst)));
            }
            if (address.type === 'vejnavn') {
                countRoads++;
                data[index].distance = $.diceCoefficient(stringNormalized, deDanishize(deNumerize(address.forslagstekst)));
            }
        });

        if (countAccessAddresses === 0 && countRoads === 0) {
            onlyAddresses = true;
        }
        if (countAddresses === 0 && countRoads === 0) {
            onlyAccessAddresses = true;
        }
        if (countAccessAddresses === 0 && countAddresses === 0) {
            onlyRoads = true;
        }

        if (onlyRoads) {
            data.sort($.dynamicSort('-distance'));
            return data;
        } else if (onlyAccessAddresses) {
            data.sort($.dynamicNatSortMultiple('-distance', 'data.postnr', 'data.husnr'));
            return data;
        } else if (onlyAddresses) {
            data.sort($.dynamicNatSortMultiple('-distance', 'data.etage', 'data.dør'));
            return data;
        }

        if (globalZip !== null) {
            stringCopy = stringCopy.replace(globalZip, '');
        }

        streetNumber = /(\d{1,3}\w?)/g.exec(stringCopy);
        if (streetNumber !== null) {
            streetNumber = streetNumber[0];
        } else {
            streetNumber = false;
        }

        if (streetNumber) {
            data.forEach(address => {
                if (address.data.husnr === streetNumber) {
                    streetNumberAddresses.push(address);
                } else {
                    dataCopy.push(address);
                }
            });
            streetNumberAddresses = sortByRealFloorThenDoor(streetNumberAddresses);

        } else {
            dataCopy = data;
        }

        if (globalZip) {
            streetNumberAddresses.forEach(address => {
                if (address.data.postnr === globalZip) {
                    streetNumberAddressesFirst.push(address);
                } else {
                    streetNumberAddressesSecond.push(address);
                }
            });

            if (streetNumber) {
                streetNumberAddressesFirst = sortByRealFloorThenDoor(streetNumberAddressesFirst);
            }

            streetNumberAddressesSecond.sort($.dynamicNatSortMultiple('-distance', 'data.postnr'));
            streetNumberAddresses = streetNumberAddressesFirst.concat(streetNumberAddressesSecond);
        }

        zipAddresses = dataCopy;
        if (zipAddresses.length > 0) {
            zipAddresses.sort($.dynamicNatSortMultiple('-distance', 'data.postnr', 'data.husnr'));
        }

        return streetNumberAddresses.concat(zipAddresses);
    }

    function navigateUp() {
        _getItemHeight();

        if (itemHeight) {
            addressList.scrollTop = currentItem.offsetTop - itemHeight - addressList.firstChild.offsetTop;
            _moveFocus(currentItem.previousSibling);
        }
    }

    function navigateDown() {
        _getItemHeight();

        if (itemHeight) {
            addressList.scrollTop = currentItem.offsetTop + itemHeight - addressList.firstChild.offsetTop;
            _moveFocus(currentItem.nextSibling);
        }
    }

    function navigateSelect() {
        if (!currentItem) {
            if (addressField.value) {
                search(addressField.value, false);
                addressField.focus();
            }
            return;
        }

        let data = currentData[currentItem.getAttribute('data-id')];

        // Chosen address is a street name
        if (data.type === 'vejnavn') {
            addressField.value = data.forslagstekst + ' ';
            search(data.forslagstekst, true);
            addressField.focus();
        }
        // Chosen address is an access address
        if (data.type === 'adgangsadresse') {

            askDawa(data.forslagstekst, true, data.data.id, newData => {
                if (newData.length === 1 && newData[0].type === 'adresse') {
                    addressField.value = newData[0].forslagstekst;
                    navigateFromList(newData[0]);
                } else {
                    $[addClass](addressList, 'loading');
                    $[removeClass](addressList.parentNode, 'search-failed');

                    _clearResults(addressList);
                    newData     = sortDawaData(newData, addressField.value);
                    currentData = newData;
                    _showResults(newData);
                    $[removeClass](addressList, 'loading');

                    addressList.scrollTop = 0;

                    addressField.value = addressField.value + ' ';
                    addressField.focus();
                }
            });
        }
        if (data.type === 'adresse') {
            addressField.value = data.forslagstekst;
            navigateFromList(data);
        }
    }

    function _clearResults() {
        // remove all previous items
        while (addressList.firstChild) {
            addressList.removeChild(addressList.firstChild);
        }
    }

    function _navigateClickFromList(e) {
        currentItem = this;
        navigateSelect();
        e.preventDefault();
    }

    function _showResults(data) {
        // append new items to list
        for (let entry in data) {
            if (entry in data) {
                let item = doc.createElement('li');
                item.appendChild(doc.createTextNode(data[entry].forslagstekst));
                item.setAttribute('data-id', entry);
                item.addEventListener('click', _navigateClickFromList);
                addressList.appendChild(item);
            }
        }

        currentItem = addressList.firstChild;
        $[addClass](currentItem, 'focus');
        $[removeClass](addressList, 'loading');
    }

    function _getItemHeight() {
        if (!itemHeight) {
            itemHeight = $.getHeight(addressList.firstChild);
        }
    }

    function _moveFocus(el) {
        if (el) {
            $[removeClass](currentItem, 'focus');
            currentItem = el;
            $[addClass](currentItem, 'focus');
        }
    }

    /**
     * Find City-name based on zip, insert into input el
     *
     * @param  {String}  zip Zipcode provided
     * @param  {Element} el  Input element to be autocompleted with the city
     *                       name
     * @return {Void}
     */
    function findCityByZip(zip, el) {
        if (win['disableDawa']) {
            return;
        }
        const url     = $.http + 'api.dataforsyningen.dk/postnumre';
        const options = {};

        let cacheName = '';

        el.setAttribute('disabled', true);

        options.url    = url;
        options.params = {nr: zip};

        cacheName = JSON.stringify(options);

        if (cache[cacheName]) {
            el.value = cache[cacheName];
            el.removeAttribute('disabled');
        } else {
            setTimeout(() => el.removeAttribute('disabled'), 10000);
            options.success = data => {
                if (data.length > 0) {
                    cache[cacheName] = data[0].navn;
                    el.value         = data[0].navn;
                }
                el.removeAttribute('disabled');
            };
            // TODO: Should this die silently?
            options.error   = () => { };
            $.xhr.get(options.url + '?' + $.parseParams(options.params))
                .success(options.success)
                .error(options.error);
        }
    }

    // Define public interface
    dawa.search         = search;
    dawa.navigateUp     = navigateUp;
    dawa.navigateDown   = navigateDown;
    dawa.navigateSelect = navigateSelect;
    dawa.findCityByZip  = findCityByZip;
    dawa.init           = init;

    win.dawa = dawa;

}(document, window, window.hoax));
